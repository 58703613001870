import React, { useState } from 'react';

function HomeContent() {


  return (
    <div className="space-y-6">
        <p>Welcome to CareerCompass brought to you by EngineerHub!</p>
        <p>Site is currently under development. So please pardon the mess </p> 
    </div>
  );
}

export default HomeContent;